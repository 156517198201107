<template>
	<div class="mainTem">
		<!-- <div class="buyerHeader">
			<h3 class="buyerHeaderTit">
				<span>{{ $t('Storage.nav.SKU_inventories') }}</span>
				<el-tooltip effect="dark" :content="$t('btn.long_Export_excel')"ng_Export_excel')" placement="top">
					<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
				</el-tooltip>
			</h3>
		</div> -->

		<div class="tableConTop">
			<el-row>
				<el-col :span="5" class="tableConTopLeft">
					<h3>
						<span>{{$t('i18nn_0950e42c6423c2a7')}}</span>
						<el-tooltip effect="dark" :content="$t('i18nn_ff6fd823ffab7e6b')" placement="top">
							<el-button type="warning" circle icon="el-icon-download" size="mini" @click="exportExcel()"></el-button>
						</el-tooltip>
					</h3>
				</el-col>
				<el-col :span="19" class="tableConTopRig">
					<el-button type="success" circle icon="el-icon-refresh" size="small" @click="initData()">
					</el-button>
				</el-col>
			</el-row>
		</div>
		<!-- <div class="formMsgCon">
        <h3><i class="titleIcon el-icon-bell"></i>{{$t('i18nn_41413cddfa61538c')}}</h3>
        <div class="formMsgDet">
          <p>
            此为录入仓库的SKU数据
          </p>
        </div>
    </div> -->
		<div class="filterCon" style="">
			<div class="filterBarList">

			</div>
			<div class="filterAction">
				<!-- <ul class="actionConList"> -->

				<!-- </ul> -->
				<ul class="filterConList">
					<li>
						<span>{{ $t('i18nn_f77f473d27f48bdc') }}</span>
						<SelAgentUser @change="changeAgentUser"></SelAgentUser>
					</li>
					<li>
						<span>{{$t('i18nn_c944a6686d996ab3')}}</span>
						<whNoSelect @changeData="changWhNo"></whNoSelect>
					</li>
					<li>
						<span>{{$t('i18nn_559fed47b0e95407')}}</span>
						<el-input :placeholder="$t('i18nn_5a9aefbc03c778f7')" size="small" v-model="filterData.inWhCode"
							 :clearable="true" style="width: 150px;" @keyup.enter.native="initData"></el-input>
					</li>
					<li>
						<span>{{ $t('i18nn_f353b1ebbdc25be4') }}</span>
						<el-input :placeholder="$t('i18nn_3ee4c9b76289e93a')" size="small" v-model="filterData.keyword"
							maxlength="50" :clearable="true" style="width: 150px;" @keyup.enter.native="initData"></el-input>
					</li>
					<li>
						<el-button icon="el-icon-search" size="small" type="primary" @click="initData()"></el-button>
					</li>
					<!-- <li>
						<el-button icon="el-icon-s-operation" size="small" type="primary" @click="openMoreFilter()"></el-button>
					</li> -->
				</ul>
			</div>
		</div>

		<div class="" v-loading="loading_load" :element-loading-text="$t('tips.loading')">
			<el-table ref="multipleTable" :data="tableData" stripe :border="true" style="width: 100%" size="small"
				:max-height="$store.state.frameConHeightWh">
				<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center"
					:label="$t('Storage.tableColumn.no')"></el-table-column>

				<el-table-column prop="statusName" :label="$t('Storage.packing_list.status')">
					<template slot-scope="scope">
						<el-tag type="" v-if="'0' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="success"
							v-else-if="'10' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="warning"
							v-else-if="'20' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="danger"
							v-else-if="'28' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="warning"
							v-else-if="'30' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="info"
							v-else-if="'36' == scope.row.status">{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
						<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'statusName') }}</el-tag>
					</template>
				</el-table-column>



				<!-- <el-table-column prop="verifyStatusName" :label="$t('i18nn_850ab41707d9ec89')">
					<template slot-scope="scope">
						<el-tag type="" v-if="'0' == scope.row.verifyStatus">{{ $Utils.i18nKeyText(scope.row,'verifyStatusName') }}
						</el-tag>
						<el-tag type="success" v-else-if="'1' == scope.row.verifyStatus">
							{{ $Utils.i18nKeyText(scope.row,'verifyStatusName') }}
						</el-tag>
						<el-tag type="info" v-else>{{ $Utils.i18nKeyText(scope.row,'verifyStatusName') }}</el-tag>
					</template>
				</el-table-column> -->
				<el-table-column prop="inWhCode" :label="$t('i18nn_35ec768075eb8f4e')" width="">
					<template slot-scope="scope">
						<div>
							<!-- <el-link v-if="scope.row.inWhCode" type="primary" size="mini" icon=""
								@click="showDetListByInWhCode($event, scope.row)">
								{{ scope.row.inWhCode }}
							</el-link> -->
							
							<div class="link-copy" v-if="scope.row.inWhCode">
								<span class="link-text" @click="showDetListByInWhCode($event, scope.row, scope.$index)">
									{{ scope.row.inWhCode }}
								</span>
								<el-tooltip class="link-copy-icon" effect="dark" :content="$t('i18nn_29f67f0644a78bf3')" placement="top">
								  <i class="el-icon-document-copy" @click="$Utils.copyText(scope.row.inWhCode)"></i>
								</el-tooltip>
							</div>
							
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="whNo" :label="$t('i18nn_5acbec83efb27445')" width="">
				</el-table-column>

				<el-table-column prop="inWhTypeName" :label="$t('i18nn_643e08ba53f72f39')" width="">
				</el-table-column>
				<el-table-column prop="relationNo" :label="$t('i18nn_158db9252e21d1a6')" width="">
				</el-table-column>
				<el-table-column prop="ctnMark" :label="$t('i18nn_73fc38e67381ae73')" width="">
				</el-table-column>

				<el-table-column prop="ctnCount" :label="$t('i18nn_4e4bec1fd140fcf7')" width="">
				</el-table-column>

				<el-table-column prop="stock" :label="$t('i18nn_48bc6e8b56cba243')" width="">
				</el-table-column>

				<el-table-column prop="lockTotal" :label="$t('i18nn_0ddc3b0b28d12b29')" width="">
				</el-table-column>
				<el-table-column prop="ctnCount" :label="$t('i18nn_51c7caf81e915eb4')" width="">
					<template slot-scope="scope">
						<strong
							class="red">{{parseFloat(scope.row.stock?scope.row.stock:0)-parseFloat(scope.row.lockTotal?scope.row.lockTotal:0)}}</strong>
					</template>

				</el-table-column>


				<!-- <el-table-column prop="inWhPallte" :label="$t('i18nn_c8773d8d74202801')" width="">
				</el-table-column> -->

				<el-table-column prop="ctnUnitName" :label="$t('i18nn_b6bf0a07fe26f74f')" width="">
				</el-table-column>

				<el-table-column prop="ctnL">
					<template slot="header">
						<div><span>{{$t('i18nn_53f9f6f694a5b0a8')}}</span>(L*W*H)</div>
					</template>
					<template slot-scope="scope">
						<div>
							{{scope.row.ctnL}}*{{scope.row.ctnW}}*{{scope.row.ctnH}}
						</div>
					</template>
				</el-table-column>

				<el-table-column prop="ctnWeight" :label="$t('i18nn_9e168db87b716866')" width="">
				</el-table-column>

				<el-table-column prop="goodsSku" width="">
					<template slot="header">
						<div><span>{{$t('i18nn_c5ef0b068e92e23d')}}</span>SKU</div>
					</template>
				</el-table-column>

				<el-table-column prop="ctnSkuCount" :label="$t('i18nn_85a10d3e5ea0e103')" width="">
				</el-table-column>

				<el-table-column prop="skuTotal" :label="$t('i18nn_bad53577db0da2d3')" width="">
				</el-table-column>

				<!-- <el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')"></el-table-column> -->
				<el-table-column prop="" :label="$t('i18nn_43a3586339251494')" min-width="220">
					<template slot-scope="scope">
						<div>
							<ul>
								<li>
									<span>{{$t('i18nn_fdc34fd7121f9c48')}}</span>:{{scope.row.updateTime}}
								</li>
								<li>
									<span>{{$t('i18nn_726c51702f70c486')}}</span>:{{scope.row.createTime}}
								</li>
							</ul>
						</div>
					</template>
				</el-table-column>
				
				<el-table-column :label="$t('Storage.tableColumn.operation')" width="160px" fixed="right">
					<template slot-scope="scope">
						<div>
							<el-button @click="showDetList($event, scope.row)" type="primary" size="mini"
								icon="el-icon-view">{{$t('i18nn_202e232a73f0abe1')}}</el-button>
						</div>

					</template>
				</el-table-column>
			</el-table>

			<div class="tableCon_pagination"><hy-page-pagination :pagination="pagination"
					v-on:updatePageData="getPageData"></hy-page-pagination></div>
		</div>

		<!--查看明细-->
		<!-- <el-dialog :title="$t('Storage.skuInfo.SKU_detail')" append-to-body :close-on-click-modal="false" :visible.sync="dialogShowDet" width="1200px" top="10px">
			<div style="">
				<el-table :data="DetData" stripe :border="true" style="width: 100%" size="small">
					<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>

					<el-table-column prop="subjectName" :label="$t('Storage.skuInfo.category')"></el-table-column>

					<el-table-column prop="goodsSku" label="SKU"></el-table-column>

					<el-table-column prop="goodsName" :label="$t('Storage.skuInfo.Chinese_title')"></el-table-column>

					<el-table-column prop="goodsNameEn" :label="$t('Storage.skuInfo.English_title')"></el-table-column>

					<el-table-column prop="goodsImg" :label="$t('Storage.skuInfo.SKU_pictures')">
						<template slot-scope="scope">
							<div>
								<el-image lazy style="width: auto; height: 40px" fit="contain" :src="scope.row.goodsImg" @click="openBigImg($event, scope.row.goodsImg)">
									<div slot="error" class="image-slot" style="font-size: 40px;"><i class="el-icon-picture-outline"></i></div>
								</el-image>
							</div>
						</template>
					</el-table-column>

					<el-table-column prop="declarePrice" :label="$t('Storage.skuInfo.price')"></el-table-column>
					<el-table-column prop="goodsWeight" :label="$t('Storage.skuInfo.weight')"></el-table-column>

					<el-table-column prop="sizeUnitName" :label="$t('Storage.skuInfo.unit')"></el-table-column>

					<el-table-column prop="goodsLength" :label="$t('Storage.skuInfo.length')"></el-table-column>
					<el-table-column prop="goodsWidth" :label="$t('Storage.skuInfo.width')"></el-table-column>
					<el-table-column prop="goodsHeight" :label="$t('Storage.skuInfo.height')"></el-table-column>
					<el-table-column prop="safeStock" :label="$t('Storage.skuInfo.safety_stock')"></el-table-column>

					<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')"></el-table-column>
				</el-table>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogShowDet = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->

		<!--查看库位明细-->
		<!-- <el-dialog :title="'SKU库位明细'" append-to-body :close-on-click-modal="false" :visible.sync="dialogSkuPlace" width="1000px" top="10px">
			<div style="" v-loading="loading_det">
				<el-table :data="SkuPlaceData" stripe :border="true" style="width: 100%" size="small">
					<el-table-column type="index" :index="$Utils.tableIndex(pagination)" fixed="left" width="50" align="center" :label="$t('Storage.tableColumn.no')"></el-table-column>

					<el-table-column prop="goodsSku" :label="'SKU'"></el-table-column>

					<el-table-column prop="whNo" :label="$t('i18nn_c944a6686d996ab3')"></el-table-column>
					<el-table-column prop="place" :label="$t('i18nn_3a6eb04ef8f3327b')"></el-table-column>

					<el-table-column prop="stock" :label="$t('i18nn_de53ab10322cd38a')"></el-table-column>

					<el-table-column prop="remark" :label="$t('Storage.tableColumn.remark')"></el-table-column>
				</el-table>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogSkuPlace = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->

		<!--查看图片-->
		<!-- <el-dialog :title="$t('FormMsg.picture')" append-to-body :close-on-click-modal="false" :visible.sync="dialogImgVisible" width="1000px" top="0">
			<div style="overflow: auto; width: 100%; height: 80%;"><img :src="imgUrlBigShow" width="auto" height="auto" /></div>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" plain @click="dialogImgVisible = false">{{ $t('FormMsg.Close') }}</el-button>
			</div>
		</el-dialog> -->

		<!-- excel 导出排序 -->
		<whExcelCustom :openTime="excelOpenTime" :excelHead="excelHead" :excelData="expExcelData" :pagination="pagination"
			:excelName="excelName" :excelOption="excelOption" :expHttpUrl="$urlConfig.WhTransferStockPageList"
			:expHttpFilter="pageFilterData()"></whExcelCustom>
		<!-- 出库明细 -->
		<TransportInventoriesOutWhDet :openTime="TraInvOutDetOpenTime" :inWhRecord="TraInvOutDetInWhRecord"
			:inWhCode="TraInvOutDetInWhRecByInCode" :row="TraInvOutDetRow"></TransportInventoriesOutWhDet>
	</div>
</template>
<script>
	// import excelUtilsNew from '@/utils/excelUtilsNew.js';
	import whNoSelect from '@/components/StorageCenter/components/whNoSelect.vue';
	import SelAgentUser from '@/components/Common/SelAgentUser.vue';

	import TransportInventoriesOutWhDet from '@/components/StorageCenter/Transport/TransportInventoriesOutWhDet.vue';
	import whExcelCustom from '@/components/StorageCenter/components/whExcelCustom.vue';
	export default {
		components: {
			SelAgentUser,
			TransportInventoriesOutWhDet,
			whExcelCustom,
			whNoSelect
		},
		data() {
			return {
				//excel导出
				excelOpenTime: '',
				excelHead: [],
				expExcelData: [],
				excelName: '',
				excelOption: {},

				TraInvOutDetOpenTime: "",
				TraInvOutDetRow: {},
				TraInvOutDetInWhRecord: "",
				TraInvOutDetInWhRecByInCode: "",
				// activeName: 'second',
				// isShowFrom:false,
				// pickerOptions1: {
				//     disabledDate(time) {
				//       return time.getTime() > Date.now();
				//     },
				//     shortcuts: [{
				//       text: this.$t('i18nn_0200bd47bb4bb83d'),
				//       onClick(picker) {
				//         picker.$emit('pick', new Date());
				//       }
				//     }, {
				//       text: this.$t('i18nn_84b73bfc6dada445'),
				//       onClick(picker) {
				//         const date = new Date();
				//         date.setTime(date.getTime() - 3600 * 1000 * 24);
				//         picker.$emit('pick', date);
				//       }
				//     }, {
				//       text: this.$t('i18nn_e3f3138cf5ba3f6d'),
				//       onClick(picker) {
				//         const date = new Date();
				//         date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
				//         picker.$emit('pick', date);
				//       }
				//     }]
				//   },
				// dialogShowDet: false,
				// DetData: [],

				// dialogSkuPlace: false,
				// loading_det: false,
				// SkuPlaceData: [],

				// UserInfo: this.$store.getters.getUserInfo,
				// dialogFormVisible: false,
				// dialogFormStatus: 0, //0-新增，1-修改

				//图片放大
				// dialogImgVisible: false,
				// imgUrlBigShow: '',

				loading: false,
				// form: {
				// 	id: null, //"数据ID",
				// 	goodsSubjectId: '', //this.$t('i18nn_268e50d838264ca8'),
				// 	goodsSku: '', //"SKU编码",
				// 	goodsName: '', //"SKU名称",
				// 	goodsNameEn: '', //"SKU英文标题",
				// 	goodsImg: '', //"SKU图片",
				// 	declarePrice: '', //this.$t('i18nn_9db48ed0e8ec64a6'),
				// 	goodsWeight: '', //this.$t('i18nn_96f1a4b17e75892d'),
				// 	sizeUnit: '', //this.$t('i18nn_38bc71c0dc55904b'),
				// 	goodsLength: '', //this.$t('i18nn_0e60303b30d5d4b4'),
				// 	goodsWidth: '', //this.$t('i18nn_6cd31871f8528dd5'),
				// 	goodsHeight: '', //this.$t('i18nn_93c9ea4a0e52c641'),
				// 	safeStock: '' //this.$t('i18nn_004617dc47191cb8')
				// },

				// formRules: {
				// 	goodsSubjectId: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	goodsSku: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	goodsName: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	goodsNameEn: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	goodsImg: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	declarePrice: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	goodsWeight: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	sizeUnit: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	goodsLength: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	goodsWidth: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	goodsHeight: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }],
				// 	safeStock: [{ required: true, message: this.$t('FormMsg.Please_Enter'), trigger: 'blur' }]
				// },
				loading_load: false,
				tableData: [],
				//分页数据
				pagination: this.$Utils.defaultPagination(),
				selectOption: {
					wh_weight_unit: [],
					wh_vol_unit: [],
					wh_goods_fee_type: [],
					// wh_no: []
					// statusList: [
					//   {
					//     value: '',
					//     label: this.$t('i18nn_16853bda54120bf1')
					//   },
					//   {
					//     value: '1',
					//     label: this.$t('i18nn_fdd8426d7b8869c5')
					//   },
					//   {
					//     value: '2',
					//     label: this.$t('i18nn_619d7e563f879811')
					//   },
					//   {
					//     value: '3',
					//     label: this.$t('i18nn_1dfb3a065d3ffe39')
					//   }
					// ]
				},
				//查询，排序方式
				filterData: {
					orderBy: 'id_', //排序字段
					sortAsc: 'desc', //desc降序，asc升序
					// "accountPeriod":"",
					// "billNo":""
					// status: '',
					// putWhNo: '',
					agentUser: '',
					whNo: '',
					inWhCode:'',
					// goodsSku: '',
					// // hashCode: '',
					// goodsName: '',
					// goodsNameEn: '',
					keyword: ''
					// declareNameCh: '',
				}
			};
		},

		//创建时
		created() {
			// this.getPageData();
		},
		//编译挂载前
		mounted() {
			// this.getDicData();

			this.initData();
		},
		methods: {
			initData() {
				this.pagination.current_page = 1;
				// this.currentSel = {};
				// this.multipleSelection = [];
				this.getPageData();
				// this.getDicData();
			},
			changWhNo(data) {
				console.log('changWhNo', data);
				this.filterData.whNo = data.code;
				// this.filterData.userId = data.userId;
				this.initData();
			},

			changeAgentUser(val) {
				this.filterData.agentUser = val;
				this.initData();
			},
			showDetList($event, row) {
				$event.stopPropagation();
				this.TraInvOutDetOpenTime = new Date().getTime();
				this.TraInvOutDetRow = row;
				this.TraInvOutDetInWhRecByInCode = "";
				this.TraInvOutDetInWhRecord = row.id;
			},
			showDetListByInWhCode($event, row) {
				$event.stopPropagation();
				// if(this.filterData.userId){
				this.TraInvOutDetOpenTime = new Date().getTime();
				this.TraInvOutDetRow = row;
				this.TraInvOutDetInWhRecord = "";
				this.TraInvOutDetInWhRecByInCode = row.inWhCode;
				// this.TraInvOutDetUserId = row.userId;
				// } else {
				// 	this.$message.warning(this.$t('i18nn_07e0f8f59293e78d'));
				// }

			},
			//库位查询
			// showSkuLocation($event, row) {
			// 	$event.stopPropagation();
			// 	this.dialogSkuPlace = true;
			// 	this.SkuPlaceData = [];
			// 	this.getSkuLocationData(row.goodsSku, row.whNo, this.filterData.agentUser);
			// 	// this.dialogShowDet = true;
			// 	// this.DetData = [row.mySku];
			// },

			//导出 excel
			exportExcel() {
				let columns = [{
						title: this.$t('Storage.packing_list.status'),
						key: 'statusName'
					},
					{
						title: this.$t('i18nn_35ec768075eb8f4e'),
						key: 'inWhCode'
					},
					{
						title: this.$t('i18nn_5acbec83efb27445'),
						key: 'whNo'
					},
					{
						title: this.$t('i18nn_643e08ba53f72f39'),
						key: 'inWhTypeName'
					},
					{
						title: this.$t('i18nn_158db9252e21d1a6'),
						key: 'relationNo'
					},
					{
						title: this.$t('i18nn_73fc38e67381ae73'),
						key: 'ctnMark'
					},
					{
						title: this.$t('i18nn_4e4bec1fd140fcf7'),
						key: 'ctnCount'
					},
					{
						title: this.$t('i18nn_48bc6e8b56cba243'),
						key: 'stock'
					},
					{
						title: this.$t('i18nn_0ddc3b0b28d12b29'),
						key: 'lockTotal'
					},
					{
						title: this.$t('i18nn_51c7caf81e915eb4'),
						key: 'canTotal',
						types: 'custom',
						formatter: function(row) {
							return parseFloat(row.stock ? row.stock : 0) - parseFloat(row.lockTotal ? row.lockTotal : 0);
						}
					},
					// {
					// 	title: this.$t('i18nn_35ec768075eb8f4e'),
					// 	key: 'inWhPallte'
					// },
					{
						title: this.$t('i18nn_b6bf0a07fe26f74f'),
						key: 'ctnUnitName'
					},
					{
						title: this.$t('i18nn_53f9f6f694a5b0a8') + '(L*W*H)',
						key: 'ctnLWH',
						types: 'custom',
						formatter: function(row) {
							return row.ctnL + "*" + row.ctnW + "*" + row.ctnH;
						}
					},
					{
						title: this.$t('i18nn_9e168db87b716866'),
						key: 'ctnWeight'
					},
					{
						title: this.$t('i18nn_c5ef0b068e92e23d'),
						key: 'goodsSku'
					},
					{
						title: this.$t('i18nn_85a10d3e5ea0e103'),
						key: 'ctnSkuCount'
					},
				];
				let Data = this.tableData;

				this.expExcelData = Data;
				this.excelHead = columns;
				this.excelName = 'TransportInventories';
				this.excelOption = {
					height: 20
				};
				this.excelOpenTime = new Date().getTime();

				// excelUtilsNew.exportExcel(columns, Data, 'WSkuInventories', { height: 20 });
			},


			// hyUpLoadImg1: function(childValue) {
			// 	// childValue就是子组件传过来的值
			// 	this.form.goodsImg = childValue;
			// },

			// //查看图片
			// openBigImg(event, imgsrc) {
			// 	event.stopPropagation();
			// 	console.log('openBigImg', imgsrc);
			// 	this.dialogImgVisible = true;
			// 	this.imgUrlBigShow = imgsrc;
			// },

			//分页的筛选项数据
			pageFilterData() {
				return {
					proxyUserId: this.filterData.agentUser ? this.filterData.agentUser : null,
					// status: this.filterData.status ? this.filterData.status : null,
					// putWhNo: this.filterData.putWhNo ? this.filterData.putWhNo : null,
					whNo: this.filterData.whNo ? this.filterData.whNo : null,
					inWhCode: this.filterData.inWhCode ? this.filterData.inWhCode : null,
					// goodsSku: this.filterData.goodsSku ? this.filterData.goodsSku : null,
					// // hashCode: this.filterData.hashCode ? this.filterData.hashCode : null,
					// goodsName: this.filterData.goodsName ? this.filterData.goodsName : null,
					// goodsNameEn: this.filterData.goodsNameEn ? this.filterData.goodsNameEn : null,
					keyword: this.filterData.keyword ? this.filterData.keyword : null
					// declareNameCh: this.filterData.declareNameCh ? this.filterData.declareNameCh : null,
				};
			},

			//请求分页数据
			getPageData() {
				let filterData = Object.assign({
						offset: (this.pagination.current_page - 1) * this.pagination.page_size, //当前页
						limit: this.pagination.page_size //当前页显示数目
					},
					this.pageFilterData()
				);
				// let _this = this;
				this.loading_load = true;
				this.$http
					.put(this.$urlConfig.WhTransferStockPageList, filterData)
					.then(({
						data
					}) => {
						// console.log(this.$store.getters.getUserInfo);
						console.log('分页，请求成功');
						console.log(data);
						
						this.loading_load = false;
						//表格显示数据
						this.tableData = data.rows;
						//当前数据总条数
						this.pagination.total = parseInt(data.total);
						//当前页数
						// this.pagination.current_page = parseInt(data.current);
						//当前页条数
						// this.pagination.page_size = parseInt(data.size);
					})
					.catch(error => {
						console.log(error);
						console.log('分页，请求失败');
						this.loading_load = false;
					});
			},
			//库位查询
			// getSkuLocationData(sku, whNo, agentUser) {
			// 	// let _this = this;
			// 	this.loading_det = true;
			// 	this.$http
			// 		.put(this.$urlConfig.WhMyGoodsSkuPlace, {
			// 			goodsSku: sku,
			// 			whNo: whNo,
			// 			proxyUserId: agentUser ? agentUser : null
			// 		})
			// 		.then(({ data }) => {
			// 			this.loading_det = false;
			// 			console.log('分页，请求成功');
			// 			console.log(data);
			// 			if (data.data) {
			// 				this.SkuPlaceData = data.data.skuStockInfo;
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('分页，请求失败');
			// 			this.loading_det = false;
			// 		});
			// },
			//操作
			// sureBillAction(row,msg,state) {
			//   console.log(this.$t('i18nn_4f5bb4ff8b3d804b'), row);
			//   console.log(row);
			//   this.$confirm(this.$t('i18nn_e8ec92802315a287')+msg+'通过该笔账单?', this.$t('tips.tipsTitle'), {
			//     //confirmButtonText: this.$t('i18nn_204ffab8a6e01870'),
			//     //cancelButtonText: this.$t('i18nn_4b3340f7872b411f'),
			//     type: 'warning'
			//   }).then(() => {
			//     //确认
			//     let dataParm = {
			//       ids:[row.id],
			//       state:state,
			//     }
			//     this.updatePageData(dataParm, msg);
			//   }).catch(() => {

			//   });

			// },
			//更新特定数据,
			// updatePageData(dataParm, msg) {
			//   // let _this = this;
			//   this.loading_load = true;
			//   //浅拷贝、对象属性的合并
			//   let dataParmAss = Object.assign({}, dataParm);
			//   // dataParmAss.isDefault = 1;
			//   this.$http.post(this.$urlConfig.PartnerMatchingFeeModifyPage, dataParmAss)
			//     .then(({ data }) => {
			//       console.log(msg + "特定数据，请求成功");
			//       console.log(data);
			//       if (200 == data.code) {
			//         this.$message.success(msg + '成功！');
			//         this.getPageData();
			//       } else {
			//         this.$message.warning(data.msg ? data.msg : msg + '失败,请重试');
			//       }
			//     })
			//     .catch((error) => {
			//       console.log(error);
			//       console.log("更新特定数据，请求失败");
			//       this.loading_load = false;
			//       this.$message.warning('' + msg + '失败,请重试！');
			//     });
			// },
			//选择数据后回调
			// selRow(event, row) {
			// 	event.stopPropagation();
			// 	this.$emit('selectRow', row);
			// },
			//查询数据字典
			// getDicData() {
			// 	// let _this = this;
			// 	// console.log(keyword);

			// 	// this.loading_load = true;
			// 	this.$http
			// 		.put(this.$urlConfig.HyDicQueryList, ['wh_no'])
			// 		.then(({ data }) => {
			// 			console.log('查询数据字典，请求成功');
			// 			console.log(data);
			// 			if (200 == data.code && data.data) {
			// 				// this.selectOption.wh_weight_unit = data.data['wh_weight_unit'];
			// 				// this.selectOption.wh_vol_unit = data.data['wh_vol_unit'];
			// 				// this.selectOption.wh_goods_fee_type = data.data['wh_goods_fee_type'];
			// 				this.selectOption.wh_no = data.data['wh_no'];
			// 			} else {
			// 				if (!data.msg) {
			// 					data.msg = this.$t('tips.submitError');
			// 				}
			// 				this.$message.warning(data.msg);
			// 			}
			// 		})
			// 		.catch(error => {
			// 			console.log(error);
			// 			console.log('查询数据字典接口，请求失败');
			// 			this.$message.error(this.$t('tips.submitRequestError'));
			// 		});
			// }
		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
	// /deep/ .el-input-number,
	// .el-select {
	// 	// width: 100px;
	// 	.el-input__inner {
	// 		text-align: left;
	// 	}
	// }
	// .form_msg {
	// 	color: #e6a23c;
	// }
</style>